import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import Modal from 'react-modal';
import { categories } from './categories';

Modal.setAppElement('#root');

const Home = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [editArticle, setEditArticle] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'articles'));
        setArticles(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        setLoading(false);
      } catch (error) {
        setError('Error fetching articles');
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Tem certeza que deseja excluir o artigo')) {
      try {
        await deleteDoc(doc(db, 'articles', id));
        setArticles(articles.filter(article => article.id !== id));
        console.log('Artigo excluído:', id);
      } catch (error) {
        console.error('Erro ao excluir artigo:', error);
      }
    }
  };

  const handleEdit = (article) => {
    setEditArticle(article);
    setEditModalIsOpen(true);
  };

  const handleSave = async () => {
    try {
      const articleDoc = doc(db, 'articles', editArticle.id);
      await updateDoc(articleDoc, editArticle);
      setArticles(articles.map(article => (article.id === editArticle.id ? editArticle : article)));
      setEditModalIsOpen(false);
      console.log('Artigo atualizado:', editArticle.id);
    } catch (error) {
      console.error('Erro ao atualizar o artigo:', error);
    }
  };

  const closeModal = () => {
    setEditModalIsOpen(false);
    setEditArticle(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditArticle(prevState => ({ ...prevState, [name]: value }));
  };

  return (
    <div className="container mx-auto py-10">
      <h1 className="text-3xl font-bold mb-6">Articles</h1>
      {loading ? (
        <p>Carregendo artigos...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div className="space-y-4">
          {articles.map((article) => (
            <div key={article.id} className="p-6 bg-white rounded shadow">
              {article.imageUrl && (
                <img src={article.imageUrl} alt={article.title} className="w-full h-auto mb-4" />
              )}
              <h2 className="text-xl font-semibold">{article.title}</h2>
              <div
                className="text-gray-600"
                dangerouslySetInnerHTML={{ __html: article.content }}
              />
              <div className="mt-4 space-x-4">
                <button
                  onClick={() => handleEdit(article)}
                  className="bg-blue-600 text-white px-4 py-2 rounded"
                >
                  Editar
                </button>
                <button
                  onClick={() => handleDelete(article.id)}
                  className="bg-red-600 text-white px-4 py-2 rounded"
                >
                  Deletar
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
      {editArticle && (
        <Modal
          isOpen={editModalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Edit Article"
          className="modal"
          overlayClassName="modal-overlay"
        >
          <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Editar Artigo</h2>
            <label className="block mb-2">
              Titulo
              <input
                type="text"
                name="title"
                value={editArticle.title}
                onChange={handleChange}
                className="block w-full mt-1 p-2 border rounded"
              />
            </label>
            <label className="block mb-2">
              Categoria
              <select
                name="category"
                value={editArticle.category}
                onChange={handleChange}
                className="block w-full mt-1 p-2 border rounded"
              >
                {categories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </label>
            <label className="block mb-2">
              Conteudo
              <textarea
                name="content"
                value={editArticle.content}
                onChange={handleChange}
                className="block w-full mt-1 p-2 border rounded"
                rows="5"
              ></textarea>
            </label>
            <label className="block mb-2">
              Image URL
              <input
                type="text"
                name="imageUrl"
                value={editArticle.imageUrl || ''}
                onChange={handleChange}
                className="block w-full mt-1 p-2 border rounded"
              />
            </label>
            <button onClick={handleSave} className="mt-4 bg-blue-600 text-white px-4 py-2 rounded">
              Salvar
            </button>
            <button onClick={closeModal} className="mt-4 bg-gray-600 text-white px-4 py-2 rounded">
              Cancelar
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Home;
