import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Header = () => {

  const { currentUser, logout } = useAuth();

  return (
    <header className="bg-white shadow">
      <div className="container mx-auto flex justify-between items-center py-4 px-6">
        <div className="flex items-center space-x-4">
          <img src="https://garagehero.com.br/wp-content/uploads/2021/09/msedge_tKfxx9Bsw8.png" alt="Logo" className="h-8" />
          {currentUser && (
            <nav className="flex space-x-6">
              <Link to="/" className="text-gray-600 hover:text-gray-900">Home</Link>
              <Link to="/add-article" className="text-gray-600 hover:text-gray-900">Adicionar artigo de blog</Link>
            </nav>
          )}
        </div>
        <div className="flex items-center space-x-4">
          {currentUser ? (
            <button onClick={logout} className="bg-blue-600 text-white px-4 py-2 rounded">Logout</button>
          ) : (
            <Link to="/login" className="text-gray-600 hover:text-gray-900">Login</Link>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
