// src/categories.js

export const categories = [
  'Mostrar tudo',
  'Começando',
  'Gerenciamento de contas',
  'Faturamento e Pagamentos',
  'Suporte técnico',
  'FAQ'
];
